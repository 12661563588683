export const LoginUrl = 'https://admin.up-family.com.au/login';
export const SignupUrl = 'https://accounts.up-family.com.au/signup';
export const EnquireUrl = 'mailto:support@up-family.com.au';
export const NewsUrl = 'https://knowledge-base.up-family.com.au/?ht_kb_category=news';

export const SupportUrl = 'http://knowledge-base.up-family.com.au';
export const OldTradeUpUrl = 'https://my.trade-up.com.au';

export const InstagramUrl = 'https://www.instagram.com/theupfamily';
export const FacebookUrl = 'https://www.facebook.com/theUpFamily';
export const LinkedinUrl = 'https://www.linkedin.com/company/trade-up-pty-ltd';
export const TwitterUrl = 'https://twitter.com/TheUpFamily';

export const TradeUp = 'Trade Up';
export const ServiceUp = 'Service Up';
export const WorkUp = 'Work Up';
export const ProjectUp = 'Project Up';
export const CheckUp = 'Check Up';
export const UpFamily = 'Up Family';
export const AllProducts = [ TradeUp, ServiceUp, WorkUp, ProjectUp, CheckUp, UpFamily ];

export const RequestContactEndpoint = 'https://xiraxzcg53.execute-api.ap-southeast-2.amazonaws.com/dev/requestcontact';

export const CategoryAllProducts = 'All Products';
export const CategoryBuilders = 'For Builders';
export const CategorySubcontractors = 'For Subcontractors';
export const CategorySiteWorkers = 'For Site Workers';
export const CategoryCompliance = 'For Compliance';
export const ProductCategories = {
  [CategoryAllProducts]: AllProducts,
  [CategoryBuilders]: [ ProjectUp, CheckUp ],
  [CategorySubcontractors]: [ TradeUp, WorkUp, ServiceUp ],
  [CategorySiteWorkers]: [ WorkUp ],
  [CategoryCompliance]: [ CheckUp ],
};

export const PrivacyPDFUrl = 'https://knowledge-base.up-family.com.au/wp-content/uploads/2020/10/Privacy-Policy-Up-Family-2020.pdf';
export const TermsPDFUrl = 'https://knowledge-base.up-family.com.au/wp-content/uploads/2020/10/The-Up-Family-TERMS-AND-CONDITIONS-OF-USE-.pdf';
export const CookiesPDFUrl = 'https://knowledge-base.up-family.com.au/wp-content/uploads/2020/10/COOKIE-POLICY-2020-Up-Family-1.pdf';

